/* =============================
   CORPS
============================= */

html, body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth; 
}

/* Loading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Pleine hauteur */
  background-color: rgb(255, 255, 255); 
}

.loading-image {
  width: 450px;
  height: 450px;
  object-fit: contain; 
  animation: fadeIn 1s ease-in-out infinite;
}

@keyframes fadeIn {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

/* base de page */
.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background-size: 100% auto;
  text-align: center;
  background-repeat: no-repeat;
  background-color: rgb(107, 68, 170);
  font-size: 1rem;
}

/* =============================
   HEADER ET PARALLAX
============================= */

.header, .headerColor {
  position: fixed;
  top: 0;
  width: 100%;
  height: 40px;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.header {
  background-color: rgba(255, 255, 255, 0.18);
}

.headerColor {
  background-color: rgba(219, 223, 17, 0.582);
}

.headerColor .nav-link {
  color: rgb(155, 52, 214);
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center; /* Centre verticalement les liens */
  gap: 10%; /* Espacement entre les liens */
  font-family: 'Source Serif Pro', 'Open Sans', sans-serif; 
  font-size: 1.2rem; /* Taille de police ajustée */
  height: 100%; 
  width: 100%;
}

/* Link de la Navbar */
.nav-link {
  color: rgb(214, 186, 58);
  transition: color 0.3s ease; /* Transition pour le survol */
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

@media (max-width: 680px) { /* 600px est un seuil courant pour les smartphones */
  .header, .headerColor, .navbar {
    height: auto; /* Permet à la barre de s'ajuster en fonction du contenu */
  }

  .navbar {
    flex-direction: column; /* Les éléments s'alignent verticalement */
  }

  .nav-link {
    display: block;
    margin-top: 10px; /* Espacement au-dessus de chaque lien */
    margin-bottom: 10px; /* Espacement en dessous de chaque lien */
    text-align: center;
  }
}

/*   Parallax   */
.backgrounds {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.background-layer1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-size: auto 100%;
  background-repeat: repeat-x;
  animation: scrollLeft linear infinite;
  will-change: transform;
  transform: translateZ(0);
  z-index: 0; 
}

@keyframes scrollLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(calc(-1 * var(--scroll-percentage))); }
}

.golden-strip {
  position: absolute;
  left: 0;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgb(219, 191, 37) 0%,
    rgba(223, 198, 57, 0.8) 100%
  );
  pointer-events: none; 
  z-index: 1; 
}

.background-layer2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(20px);
  opacity: 0;
  transition: filter 1s ease-out, opacity 1s ease-out;
  z-index: 2; 
}

.background-layer2 {
  opacity: 0; /* Commence invisible */
  animation: fadeInOpacity 0.3s ease-in-out forwards; /* Animation d'opacité */
}

.background-layer1.loaded, .background-layer2.loaded {
  filter: blur(0);
  opacity: 1;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1; /* Devient complètement visible */
  }
}

/* Petits appareils (téléphones, 600px et moins) */
@media only screen and (max-width: 600px) {
  .backgrounds, .background-layer1, .background-layer2 {
    height: 70vh; 
  }
  .mobile-top {
    top: calc(70vh - 170px);
  }
}

/* Tablettes (601px - 900px) */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .backgrounds, .background-layer1, .background-layer2 {
    height: 75vh; 
  }
  .mobile-top {
    top: calc(75vh - 170px); 
  }
}

/* Petits écrans (901px - 1200px) */
@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .backgrounds, .background-layer1, .background-layer2 {
    height: 85vh; 
  }
}

/* Logo */
.logo-style {
  width: 137px; 
  height: auto;
  padding-right: 10px;
  vertical-align: middle;
}

@media (max-width: 900px) {
  .logo-style {
    width: 100px; /* Réduction de la taille du logo pour les petits écrans */
  }
}

@media (max-width: 600px) {
  .logo-style {
    width: 80px; /* Réduction encore plus grande pour les très petits écrans */
    margin-right: 5px; /* Réduction de l'espacement à droite */
  }
}

@media (max-width: 400px) {
  .logo-style {
    width: 60px; /* Taille minimale pour les écrans très petits */
    margin-right: 3px; /* Réduction supplémentaire de l'espacement */
  }
}

/* H1 et H2 du Parallax */
.beginH1 {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: rgba(255, 255, 255, 0.747);
  font-size: 5vw;
  font-family: 'Source Serif Pro', 'Open Sans', 'Times New Roman', serif;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.425);
  margin-bottom: 0.2rem; /* Espace par défaut entre H1 et H2 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Ombre pour le texte */
}

.part1-title {
  background: linear-gradient(90deg, rgb(60, 113, 210), rgb(78, 187, 231), rgb(208, 83, 215));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.part2-title {
  background: linear-gradient(90deg, rgba(208, 220, 75, 0.895), rgba(234, 227, 29, 0.9));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.part1-title, .part2-title {
  text-shadow: 2px 2px 4px rgba(99, 98, 112, 0.447); 
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.425);
}

.beginH2 {
  z-index: 2;
  color: rgba(255, 255, 255, 0.528);
  font-size: 3vw;
  font-family: 'Source Serif Pro', 'Open Sans', 'Times New Roman', serif;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.37);
}

/* Ajustements pour les petits écrans */
@media (max-width: 1500px) {
  .beginH1 {
    margin-bottom: 0.2rem; /* Réduction de l'écart entre H1 et H2 */
    font-size: 4.5rem; /* Ajustement de la taille du texte */
  }

  .beginH2 {
    font-size: 2rem; /* Ajustement de la taille du texte */
  }
}

@media (max-width: 1120px) {
  .beginH1 {
    margin-bottom: 0.2rem; /* Réduction supplémentaire de l'espace */
    font-size: 4.2rem; /* Ajustement de la taille du texte */
  }

  .beginH2 {
    font-size: 2rem;
  }
}

@media (max-width: 900px) {
  .beginH1 {
    margin-bottom: 0.2rem; /* Encore moins d'espace */
    font-size: 3.8rem; /* Ajustement de la taille du texte */
  }

  .beginH2 {
    font-size: 2rem; /* Ajustement de la taille du texte */
  }
}

@media (max-width: 600px) {
  .beginH1 {
    margin-bottom: 0.2rem; /* Réduction maximale de l'espace entre H1 et H2 */
    font-size: 3rem; /* Ajustement de la taille du texte */
  }

  .beginH2 {
    font-size: 1.6rem; /* Ajustement de la taille du texte */
  }
}

@media (min-width: 600px) and (max-width: 1100px) {
  .beginH1 {
    font-size: 3.2rem;
  }
}

.mini-title {
  position: absolute;
  z-index: 1000;
  width: auto; /* Largeur basée sur le contenu */
  white-space: nowrap; /* Empêche le retour à la ligne */
  right: 20px; /* Marge fixe à droite */
  top: 20px; /* Position finale en haut */
  transform-origin: center; /* Pour que le scale parte du centre */
  transform: translate(-50%, -50%);
}

/* Responsive design */
@media (max-width: 768px) {
  .mini-title {
    display: none;
  }
}

/* Bouton d'aide à l'utilisateur */
.scroll-button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background-color: #ff6f61;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 500; 
  top: var(--button-top, calc(100vh - 44px - 11%));
}

.has-cookie-banner .scroll-button {
  --button-top: calc(95vh - 124px);
}

.scroll-button:hover {
  background-color: #e65b50;
  transform: translateX(-50%) scale(1.05);
}

.scroll-button:active {
  transform: translateX(-50%) scale(0.95);
}

/* Media queries */
@media only screen and (max-width: 600px) {
  .scroll-button {
    padding: 10px 20px;
    font-size: 14px;
    --button-top: calc(70vh - 20px); /* Sans bannière */
  }
  .has-cookie-banner .scroll-button {
    --button-top: calc(70vh - 25px - 2%); /* Avec bannière */
  }
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .scroll-button {
    --button-top: calc(78vh);
  }
  .has-cookie-banner .scroll-button {
    --button-top: calc(70vh - 50px);
  }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
  .scroll-button {
    font-size: 14px;
    --button-top: calc(85vh - 100px);
  }
  .has-cookie-banner .scroll-button {
    --button-top: calc(80vh - 50px);
  }
}

/* particules */
.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; 
  overflow: hidden;
}

.particle {
  width: 16px;
  height: 16px;
  border-radius: 50%; /* Particules rondes */
  position: absolute;
  pointer-events: none; 
  /*box-shadow: 0 0 15px rgba(255, 250, 219, 0.894);  Halo lumineux */
  animation: shimmer 2s infinite alternate; /* Scintillement doux */
}

.particle.color1 {
  background: radial-gradient(
    circle,
    rgba(255, 226, 65, 0.9) 0%,    
    rgba(255, 218, 7, 0.134) 60%,   
    rgba(255, 217, 0, 0) 100%  
  );
  box-shadow: 0 0 15px rgba(255, 226, 65, 0.9);
}

.particle.color2 {
  background: radial-gradient(
    circle,
    rgba(255, 230, 85, 0.9) 0%,    
    rgba(255, 200, 20, 0.134) 60%, 
    rgba(255, 180, 0, 0) 100%      
  );
  box-shadow: 0 0 15px rgba(255, 230, 85, 0.9);
}

.particle.color3 {
  background: radial-gradient(
    circle,
    rgba(255, 220, 100, 0.9) 0%,   
    rgba(255, 200, 40, 0.134) 60%,
    rgba(255, 190, 50, 0) 100%  
  );
  box-shadow: 0 0 15px rgba(255, 220, 100, 0.9);
}

@keyframes shimmer {
  0% {
    box-shadow: 0 0 10px rgba(255, 219, 18, 0.685);
  }
  100% {
    box-shadow: 0 0 20px rgb(243, 214, 49);
  }
}

@media (prefers-reduced-motion: reduce) {
  .particle {
    animation: none;
    display: none;
  }
}

/* =================================
    PATH et RETOUR
================================= */

/* Style du bouton */
.scroll-to-top-button {
  position: fixed;
  left: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Bordure subtile */
  background-color: rgba(255, 255, 255, 0.462); /* Fond légèrement plus opaque */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Ombre douce */
  border-radius: 8px; /* Coins légèrement arrondis */
  padding: 8px 12px; /* Padding ajusté pour un look équilibré */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out, transform 0.3s ease, background-color 0.3s ease;
}

/* État visible */
.scroll-to-top-button.visible {
  opacity: 1;
  pointer-events: auto;
}

/* Effet au survol */
.scroll-to-top-button:hover {
  background-color: rgba(234, 220, 254, 0.694); /* Fond plus clair au survol */
  transform: scale(1.05); /* Légère mise à l'échelle */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Ombre renforcée */
}

/* Effet au focus (accessibilité) */
.scroll-to-top-button:focus {
  outline: none;
  border-color: rgba(0, 110, 255, 0.5); /* Bordure bleue discrète */
}

/* Style de l'image */
.scroll-to-top-button img {
  transform: scaleY(-1); /* Inverse verticalement */
  width: 45px; /* Taille originale conservée */
  height: auto; /* Proportion maintenue */
  display: block;
  filter: grayscale(10%); /* Effet subtil sur l'image */
  transition: filter 0.3s ease;
}

/* Effet hover sur l'image */
.scroll-to-top-button:hover img {
  filter: grayscale(0%) contrast(140%); /* Image plus vive au survol */
}

/* Responsive adjustments */

/* Tablette (768px - 991px) */
@media (max-width: 991px) {
  .scroll-to-top-button {
    left: 15px;
    top: 15px;
    padding: 6px 10px;
  }

  .scroll-to-top-button img {
    width: 45px;
  }
}

/* Mobile (moins de 767px) */
@media (max-width: 767px) {
  .scroll-to-top-button {
    left: 10px;
    top: 10px;
    padding: 5px 8px;
  }

  .scroll-to-top-button img {
    width: 40px;
  }
}

/* Très petits écrans (moins de 480px) */
@media (max-width: 480px) {
  .scroll-to-top-button {
    left: 8px;
    top: 8px;
    padding: 4px 6px;
  }

  .scroll-to-top-button img {
    width: 35px;
  }
}

.sommaire-nav {
  position: absolute;
  text-decoration: none;
  color: rgb(216, 194, 98);
  margin-left: 20px;
  display: block;
  text-align: left;
  font-size: 1.1rem;
  margin-bottom: 15px; 
  transition: color 0.3s ease;
  margin-top: 45px;
  z-index: 20;
}

.sommaire-nav:hover {
  color: rgb(255, 223, 128); 
}

.current-path-indicator {
  position: absolute;
  left: 20px;
  margin-top: 10px;
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: bold;
  opacity: 0.8;
  z-index: 10; /* Place l'indicateur au-dessus du Parallax */
}

@media (max-width: 680px) {
  .current-path-indicator {
    margin-top: 164px;
  }
  .sommaire-nav {
    margin-top: 215px;
  }
}

.course-top {
  top: 65px;
}

.default-top {
  top: 25px;
}

/* =============================
   CONTENT
============================= */

.desktop-padding {
  margin-top: 7%;
}

.mobile-padding {
  margin-top: 255px;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  font-family: 'Alegreya', 'Times New Roman', 'Open Sans', serif;
  font-size: 1.8rem;

  padding-left: 13%;
  padding-right: 13%;
  padding-bottom: 3%;

  max-width: 100vw;
  height: 100vh;
  box-sizing: border-box; /* Pour inclure le padding dans la largeur */
  overflow: hidden; /* Pour éviter tout débordement extérieur */
  z-index: 1;
}

.content p {
  text-align: left;
  width: 100%;
}

.p_transi {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease-out, transform 0.4s ease-out;
}

.p_transi.visible {
  opacity: 1;
  transform: translateY(0);
}

.content a {
  text-decoration: none;
  color: rgb(191, 97, 228);
}

@media (max-width: 625px) {
  .content p {
    padding-top: 10px;
  }
}
@media (max-width: 680px) {
  .content {
    padding-left: 3%;   
    padding-right: 3%;  
    font-size: 1.5rem; 
  }
  .content .mobile-padding {
    margin-top: 245px;
  }
}
@media (min-width: 680px) and (max-width: 900px) {
  .desktop-padding {
    margin-top: 20%;
  }
}
@media (min-width: 680px) and (max-width: 1100px) {
  .content {
    padding-left: 5%;   
    padding-right: 5%;  
    font-size: 1.5rem; 
  }
}
@media (min-width: 900px) and (max-width: 1100px) {
  .desktop-padding {
    margin-top: 13%;
  }
}
@media (min-width: 1100px) and (max-width: 1500px) {
  .content {
    padding-left: 7%;   
    padding-right: 7%;
  }
  .desktop-padding {
    margin-top: 11%;
  }
}

.home-background {
  position: relative;
  z-index: 1; /* Crée un nouveau contexte d'empilement */
}

.home-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./pictures/homeBackground.jpg');
  background-repeat: repeat;
  opacity: 0.2;
  z-index: -1; /* Place l'image en arrière-plan */
}

/*  le contenu est au-dessus de l'image de fond */
.home-background > * {
  position: relative;
  z-index: 1;
}

/* =============================
   STYLE GENERAL DU TEXTE
============================= */

h1 {
  font-family: 'Source Serif Pro', 'Open Sans', 'Times New Roman', serif;
}

h1, h2 {
  width: 90%;
}

.chapitre {
  margin-top: 6%;
  margin-bottom: 10%;
}

.bold
{
  font-weight: bold;
}

.centre {
  text-align: center;
}

.underline
{
  text-decoration: underline;
}

.citation {
  margin: 10px 50px 10px;
}

.gold
{
  color: rgb(204, 180, 41);
}

.gold:hover
{
  color: rgb(219, 194, 52);
}

.fonce
{
  color: rgb(210, 172, 209) !important;
}

/* Effet de soulignement existant */
.link_hover {
  position: relative;
  color: #FFD700 !important;
  text-decoration: none;
  padding-bottom: 2px;
  transition: color 0.3s ease;
}

.link_hover::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #FFD700;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.link_hover:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

/* Bordure élégante */
.elegant-link {
  text-align: center;
  font-weight: bold;
  border: 2px solid #FFD700;
  padding: 8px 32px;          /* Augmenté de 16px à 32px horizontalement */
  border-radius: 8px;
  transition: all 0.3s ease;
  display: inline-block;      /* Nécessaire pour que min-width fonctionne */
  min-width: 200px;          /* Largeur minimale ajoutée */
}

.elegant-link:hover {
  border-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

.animated-line {
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  font-size: 2.3rem;
  line-height: 1.1;
  min-height: 75vh;
  text-align: center;
}

@media (max-width: 600px) {
  .animated-line {
    font-size: 1.7rem;
  }
}

.animated-line h1 {
  font-size: 4vw;
}

@media (max-width: 600px) {
  .animated-line h1 {
    font-size: 4rem;
  }
}
@media (min-width: 600px) and (max-width: 1100px) {
  .animated-line h1 {
    font-size: 4.5rem;
  }
}
@media (min-width: 1100px) and (max-width: 1600px) {
  .animated-line h1 {
    font-size: 5.5rem;
  }
}

.subsection-title {
  margin-top: 15%;
}

/* Texte cliquable */
.clickable-text::before {
  content: "\f054"; /* Unicode Font Awesome pour la flèche */
  font-family: "Font Awesome 6 Free";
  color: #ffc107; /* Couleur dorée */
  margin-right: 8px; /* Espacement entre la flèche et le texte */
  font-size: 1.2rem; /* Ajuste la taille si nécessaire */
}

.clickable-text {
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
  display: inline-flex; 
  align-items: center;
  max-width: max-content;
  padding: 4px 10px;
  border-radius: 5px;
  background-color: #f0f8ff; /* Couleur subtile pour l'arrière-plan */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.hidden-text, .clickable-text {
  box-sizing: border-box;
}

.clickable-text:hover {
  background-color: #e0f0ff; /* Couleur d'arrière-plan plus marquée */
  transform: scale(1.03); /* Zoom léger pour indiquer l'interactivité */
}

.clickable-text:active {
  background-color: #cce7ff; /* Couleur différente au clic */
  transform: scale(0.95); /* Légère réduction pour un effet de "pression" */
}

/* Texte caché -> visible */
.hidden-text {
  text-align: left;
  padding: 10px;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, max-height 0.3s ease;
}

.hidden-text.visible {
  opacity: 1;
}

/* =============================
   CONTENU 
============================= */

.defi {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 20px;
  margin: 20px auto;
  max-width: 700px;
  color: #ffffff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.defi strong {
  color: #ffe680;
}

.defi ul {
  padding-left: 20px; 
  margin-left: 0; 
}

.defi li {
  margin-bottom: 10px;
}

.defi em {
  font-style: italic;
  color: #c9f0ff;
}

/* Ajustement smartphone */
@media (max-width: 600px) {
  .defi {
    padding: 15px;
    margin: 15px;
  }

  .defi ul {
    padding-left: 15px; 
  }
}

.clouds-section { 
  position: relative;
  width: calc(100% + 36%); /* Ajuster pour les marges négatives */
  margin-left: -13%;
  margin-right: -13%;
}

.clouds-begin {
  background: url('./pictures/nuages_entree.png') no-repeat top center;
  background-size: cover;
  height: 100vh;
  padding-bottom: calc(30%);
}

.clouds-content {
  position: relative;
  background-color: white;
  color: rgb(38, 20, 143);
  padding-left: 13%;
  padding-right: 13%;
  box-sizing: border-box;
}

.clouds-end {
  background: url('./pictures/nuages_sortie.png') bottom no-repeat;
  background-size: cover;
  height: 70vh;
}

.cloud {
  position: absolute;
  background-size: contain; 
  background-repeat: no-repeat;
  width: 480px;
  height: 320px;
  z-index: 10;
}

/* Pour les écrans moyens */
@media (min-width: 768px) {
  .cloud {
    width: 1024px;
    height: 683px;
  }
}

/* Pour les grands écrans */
@media (min-width: 1200px) {
  .cloud {
    width: 1920px;
    height: 1280px;
  }
}

.img_fleche {
  display: block;
  width: 50px;
  min-width: 2%;
  max-width: 100px;
  height: auto;
  margin-left: auto; 
  margin-right: auto;
}

/* Pour les écrans plus petits */
@media (max-width: 600px) {
  .img_fleche {
    width: 40px; /* Plus petite sur les petits écrans */
  }
}

/* Pour les écrans de taille moyenne */
@media (min-width: 601px) and (max-width: 1024px) {
  .img_fleche {
    width: 48px; /* Taille moyenne sur les écrans moyens */
  }
}

/* Pour les écrans plus grands */
@media (min-width: 1025px) {
  .img_fleche {
    width: 60px; /* Plus grande sur les grands écrans */
  }
}

.block {
  display: block;
  margin-top: 2vh;
}

.photo {
  max-width: 200px;
  margin-left: 20px;
  margin-right: 40px;
}

.image img {
  max-width: 100%;
  height: auto;
}

.gif, .img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto; /* Pas de largeur fixe */
  height: auto; /* Hauteur calculée automatiquement */
  max-width: 70%; /* Ajuste la taille maximale selon tes besoins */
  max-height: 70vh; /* Assure qu'il ne déborde pas de la hauteur de l'écran */
  margin: 0 auto; /* Centrage horizontal si nécessaire */
}

.gif img, .img {
  width: 100%; /* L'image s'adapte au conteneur */
  height: auto; /* Conserve les proportions */
  object-fit: contain; /* Ajuste le contenu sans le déformer */
}

/* Media query pour les plus petits écrans */
@media (max-width: 600px) {
  .gif, .img {
    width: 80%;
    padding-top: 15%; /* Ajuster la marge en haut */
    padding-bottom: 15%; /* Ajuster la marge en bas */
  }
}

@media (max-width: 400px) {
  .gif, .img {
    width: 100%;
    padding-top: 10%; /* Ajuster la marge en haut */
    padding-bottom: 10%; /* Ajuster la marge en bas */
  }
}

.gif iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  display: block;
}

.image-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px; /* Cela crée un espace entre les lignes et les colonnes */
  padding: 0 10% 5% 10%; /* 10% de padding de chaque côté */
}

.image-container img {
  width: 100%; /* Les images rempliront leur cellule de grille */
  height: auto; /* Maintient le rapport hauteur/largeur */
  object-fit: cover;
}

@media (min-width: 1800px) {
  .image-container {
    grid-template-columns: repeat(2, 1fr); /* Deux colonnes sur les écrans larges */
  }
}

.container-trail {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.trailsText {
  color: rgb(255, 255, 255);
  font-weight: bold;
  position: relative;
  width: 100%;
  line-height: 1.2;
  font-size: 3.8rem; /* Taille par défaut */
  letter-spacing: -0.05em;
  will-change: transform, opacity;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
}

@media (max-width: 1500px) {
  .trailsText {
    font-size: 3.6rem;
  }
}

@media (max-width: 1300px) {
  .trailsText {
    font-size: 3.3rem;
  }
}

@media (max-width: 768px) {
  .trailsText {
    font-size: 2.6rem;
  }
}

@media (max-width: 506px) {
  .trailsText {
    font-size: 2.2rem;
  }
}

/*   Vidéos   */
.video-container {
  position: relative;
  width: 100%; /* Prend toute la largeur de son conteneur parent */
  padding-bottom: 56.25%; /* Ratio 16:9 => 9 / 16 = 0.5625 */
  overflow: hidden;
}

.video-container iframe { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* La vidéo s'ajuste à la largeur du conteneur */
  height: 100%; /* La hauteur est calculée automatiquement */
  border: 0; /* Supprime les bordures */
}

.checkpoint-button {
  display: inline-block;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 12px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkpoint-button:disabled {
  background-color: #d3d3d3;
}

/* =============================
   UL, LI, BUTTON 
============================= */
ul {
  list-style: none; 
  padding-left: 0; /* Enlève le padding par défaut */
  width: 80%;
  margin: 0 auto;
}

@media (max-width: 700px) {
  ul {
    width: 100%;
  }
}

@media (min-width: 700px) and (max-width: 1200px) {
  ul {
    width: 95%;
  }
}

li {
  position: relative; /* Nécessaire pour positionner correctement le pseudo-élément */
  overflow: hidden; /* Garde le pseudo-élément à l'intérieur des limites de 'li' */
  border: 1px solid #ddd; /* Bordure de la carte */
  padding: 15px; /* Padding à l'intérieur de la carte */
  border-radius: 5px; /* Coins arrondis */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Ombre légère */
  margin-bottom: 10px; /* Espace entre les cartes */
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
}

li::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px; /* Taille initiale du cercle */
  height: 20px;
  background-color: #caae32; /* Couleur du cercle */
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0); /* Commence réduit */
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}

li:hover {
  color: #cfbc0e;
  border-color: #cfbc0e;
  transform: scale(1.03); 
  transition: transform 0.3s ease-in-out;
}

li.active::before {
  transform: translate(-50%, -50%) scale(90); /* SCALE ~37 pour plus petit et 60 pour grand écran*/
}

li.active:hover {
  color: #ffffff;
}

section p ul li {
  border: none; /* Reset de la bordure */
  transition: none; /* Désactive toute animation héritée */
  animation: none;
}

section p ul li:hover,
section p ul li a:hover {
  transition: none;
  animation: none;
  transform: none;
  color: inherit; /* Empêche le changement de couleur */
  background: none; /* Empêche un fond au hover */
  cursor: default;
}

.button {
  text-align: center; /* Centre le bouton dans le conteneur */
  margin-top: 20px; /* Espace au-dessus du bouton */
}

.sommaire-button, .discord-button {
  display: inline-block; 
  background-color: #c5cf2ecb; 
  color: rgb(255, 255, 255); 
  padding: 10px 20px; 
  border: none; 
  border-radius: 5px; 
  text-decoration: none; 
  font-size: 16px; 
  transition: background-color 0.3s;
  z-index: 1;
  cursor: pointer;
}

.button .discord-button {
  color: rgba(252, 246, 255, 0.904); 
}

.sommaire-button:hover {
  background-color: #8a3ce4; 
}

.discord-button {
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out; 
}

.discord-button:hover {
  background-color: #c5d345;
  transform: scale(1.1); 
  transition: transform 0.4s ease-in-out, background-color 0.4s ease-in-out; 
}

/* =============================
   Liste Cours 
============================= */

.listeCours_p {
  width: 100%;
}

.listeCours_p span a {
  text-decoration: none;
  color: #4a90e2; /* Couleur du lien */
  font-weight: bold;
  transition: color 0.3s ease; /* Transition douce pour la couleur */
}
.listeCours_p span a:hover {
  color: #f89130; /* Couleur au survol */
  text-decoration: underline; /* Soulignement au survol */
}

.name, .offre {
  color: #4a90e2;
}

/* Liste des programmes */
.programmes-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6vh;
  padding: 1%;
  margin-top: 5%;
}

.programme-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 3px solid #e7cd77; /* Légère bordure pour définir les cartes */
  border-radius: 10px; /* Bords arrondis */
  width: 100%; /* Ajustez selon la largeur souhaitée */

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre légère pour un effet de profondeur */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease, background-color 0.4s ease; /* Transition pour l'interaction au survol */
  cursor: pointer;
  overflow: hidden;

  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;   
}

.programme-item h1 {
  background-color: #f2f2f2; /* Choisir une couleur de fond appropriée */
  /*background-image: url('chemin_vers_votre_image_etoiles.png');  Image de fond avec des étoiles */
  background-repeat: repeat;
  background-size: 50px 50px; /* Taille des étoiles */
  color: #281e55;
  font-family: 'Alegreya', serif;
  margin: 0; /* Supprime la marge par défaut */
  padding: 5px 0 5px 0; 
  width: 100%; /* S'étend sur toute la largeur */
  font-size: 2.2rem;
  transition: background-color 0.3s ease;
}

.programme-item p {
  color: #24242b; /* Couleur du texte */
  padding-left: 2%;
  padding-right: 2%;
  width: 96%;
}

.dispo {
  font-size: 0.9em;
  font-weight: bold;
  font-style: italic; 
}


@media (min-width: 1800px) {
  .programme-item, .listeCours_p {
    width: 80%;
  }
}

.programme-item:hover {
  opacity: 1;
  transform: translateY(-5px); /* Effet de lévitation au survol */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Ombre plus prononcée au survol */
}

.programme-item:hover h1 {
  background-color: #f89130;
}

.programme-item.animate {
  background-color: rgba(255, 255, 255, 0.544);
}

/* Bouton caché par défaut */
.get-button {
  background-color: #a05dc7;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.3rem;
  font-family: 'Source Serif Pro', 'Open Sans', 'Times New Roman', serif;
  text-align: center;
  text-decoration: none;
  display: block; 
  margin: 4px 2px;
  width: calc(100% - 4px); 
  transition: all 0.5s ease;
  max-height: 0; /* Caché */
  overflow: hidden;
  opacity: 0;   /* Caché */
}

.get-button:hover {
  background-color: #c49ad8;
  border: 1px solid rgb(204, 180, 41);
  color: rgba(0, 0, 0, 0.706) !important;
}

/* Bouton visible au survol du parent */
.programme-item:hover .get-button {
  max-height: 100px; /* Devient visible */
  opacity: 1;        /* Devient visible */
}

.error-wrapper {
  transition: max-height 0.3s ease-in-out; /* Utilise max-height pour une transition fluide */
  max-height: 70px; /* Ajuste cette valeur selon la hauteur max de ton message */
  overflow: hidden;
}
@media (max-width: 768px) {
  .error-wrapper {
    max-height: 100px;
  }
}
.error-wrapper.fade-out {
  max-height: 0;
}
.error-message {
  color: #ac1e65 !important;
  font-weight: bold;
  animation: fadeIn 0.3s ease-in-out;
}
.error-message.fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

/* =============================
   PAGE PROFILE
============================= */

.profile-container {
  width: 90%;
  margin: 0 auto 3%;
  padding: 20px;
  background: linear-gradient(145deg, #ffffff, #f0f0f0ae);
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
}

.profile-container h1 {
  text-align: center;
  color: #3f31d9;
  margin-bottom: 20px;
}

.profile-info {
  margin-bottom: 30px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-info p {
  margin: 10px 0;
  color: #282828;
}

.profile-info p strong {
  color: #575bd0;
}

.profile-courses {
  margin-top: 30px;
}

.profile-courses h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #ffffff;
  border-bottom: 2px solid #4a90e2;
  padding-bottom: 5px;
}

.profile-courses ul {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
  cursor: default;
}

.profile-courses li {
  background: linear-gradient(145deg, #ffffff3c, #ffffff);
  padding: 20px;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.profile-courses li:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: default;
}

.profile-courses li h3 {
  margin: 0 0 10px;
  color: #4a90e2;
}

.profile-courses li p {
  margin: 0;
  font-size: 1em;
  color: #666;
  line-height: 1.5em;
}

.profile-courses a {
  color: #9d4ae7;
}

.profile-courses a:hover {
  color: #9cac21;
}

.manage-subscription-link {
  display: inline-block;
  color: #3494d5; /* Couleur bleue Stripe */
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #007ccc;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-bottom: 15px 10px;
}

.manage-subscription-link:hover {
  background-color: #007ccc;
  color: white;
}

.profile-progress {
  border-top: #cfbc0e solid 2px;
  margin-top: 40px;
}

.progress-items {
  margin-bottom: 20px;
  padding: 15px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.progress-items h2 {
  color: #999865;
}

.progress-items h3 {
  color: #4caf50;
  margin-bottom: 10px;
}

.progress-items ul {
  list-style: none;
  padding-left: 0;
}

.progress-items li {
  margin-bottom: 5px;
  color: #555;
}

.checkpoint-item {
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
}

.checkpoint-details {
  margin-top: 5px;
  height: 0; /* Contenu initialement caché */
  overflow: hidden; /* Empêche le débordement pendant la transition */
  transition: height 0.6s ease; /* Transition fluide sur la hauteur */
  background-color: #f9f9f9;
  padding: 0;
  border: none;
}

.checkpoint-item.open .checkpoint-details {
  height: auto; /* La hauteur réelle est calculée dynamiquement */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checkpoint-details h4 {
  margin-bottom: 5px;
  font-size: 1rem;
  color: #4caf50;
}

.checkpoint-details p {
  margin: 0;
  font-size: 0.9rem;
  color: #555;
}

.profile-container h1,
.profile-container h2 {
  display: block; 
  width: 100%; 
}

.chapter-details {
  overflow: hidden;
  height: 0;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  opacity: 0;
}

.chapter-details.expanded {
  max-height: 500px; /* Ajuste selon la taille de contenu max */
  opacity: 1;
}

.chapter-details.collapsed {
  max-height: 0;
  opacity: 0;
}

.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 8px;
}

.chapter-progress {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chevron-icon {
  font-size: 1.5rem; /* Taille ajustée */
  margin-right: 8px; /* Espacement entre l'icône et le texte */
  transition: transform 0.3s ease; /* Animation de rotation si besoin */
}

.course-title i {
  transition: transform 0.3s ease;
}
.course-title.open i {
  transform: rotate(90deg); /* Fait pivoter la flèche */
}

.chapters-container {
  height: 0; /* Contenu caché initialement */
  overflow: hidden; /* Empêche le débordement */
  transition: height 0.6s ease; /* Transition fluide sur la hauteur */
}

.chapters-container {
  height: 0; /* Contenu caché initialement */
  overflow: hidden; /* Empêche le débordement */
  transition: height 0.6s ease; /* Transition fluide */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .profile-container {
    width: 85%;
    margin-top: 6%;
  }
}

@media (min-width: 768px) and (max-width: 1600px) {
  .profile-container {
    margin-top: 5%;
  }
}
@media (min-width: 1500px) {
  .profile-container {
    width: 100%;
    margin-top: 3%;
  }
}

/* =============================
   FENETRE DE PAIEMENT
============================= */

/* Overlay */
.overlay-checkout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.199); 
  z-index: 50; 
}

/* Formulaire */
.form-checkout { 
  position: fixed; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff; 
  color: rgb(0, 110, 255);
  font-size: 1.5rem;
  width: 90%;
  max-width: 560px;
  max-height: 90vh; /* Ajout pour éviter le débordement */
  overflow-y: auto; /* Scroll si contenu trop long */
}

/* Bouton de soumission */
.form-checkout .payment-submit {
  background-color: #674acf;
  color: #fff;
  font-size: 12px;
  padding: 10px 45px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
}

/* Sélecteur de méthode de paiement */
.payment-method-selector {
  display: flex;
  gap: 5%; 
}

/* Media Queries */
/* Tablette (768px - 991px) */
@media (max-width: 991px) {
  .form-checkout {
    width: 90%;
    font-size: 1.4rem;
    padding: 18px;
  }

  .form-checkout .payment-submit {
    padding: 10px 40px;
  }

  .payment-method-selector {
    gap: 3%; /* Réduction légère pour éviter un espacement trop large */
  }
}

/* Mobile (moins de 767px) */
@media (max-width: 767px) {
  .form-checkout {
    width: 90%;
    font-size: 1.4rem;
    padding: 15px;
  }

  .form-checkout .payment-submit {
    padding: 8px 35px;
  }

  .payment-method-selector {
    flex-direction: column; /* Empilement vertical pour mobile */
    gap: 10px; /* Valeur fixe pour plus de contrôle */
  }
}

/* Très petits écrans (moins de 480px) */
@media (max-width: 480px) {
  .form-checkout {
    width: 95%; /* Plus large pour maximiser l'espace */
    font-size: 1.2rem; /* Réduction pour lisibilité */
    padding: 12px;
  }

  .form-checkout .payment-submit {
    padding: 8px 30px;
    font-size: 11px; /* Légère réduction */
  }

  .payment-method-selector {
    gap: 8px;
  }
}

.close-button {
  position: absolute;
  top: 10px; /* Ajustez selon les besoins */
  right: 10px; /* Ajustez selon les besoins */
  border: none;
  background-color: transparent; /* Rend le fond du bouton transparent */
  cursor: pointer;
}

.close-button img {
  width: 20px; /* Ajustez la taille de l'image selon les besoins */
  height: auto;
}

.price-text {
  color: rgb(56, 49, 143); 
}

.access-text {
  color: rgb(200, 190, 98); 
  font-weight: bold;
}

/* pour le champs de paiment */
.payment-details {
  margin: 20px 4%;
}

.user-info {
  margin-top: 20px; /* Espace au-dessus de la section */
  padding: 10px; /* Espacement interne */
  background-color: #f9f9f9; /* Fond léger */
  border: 1px solid #ddd; /* Bordure subtile */
  border-radius: 8px; /* Coins arrondis */
  font-family: Arial, sans-serif; /* Police lisible */
  color: #333; /* Couleur de texte standard */
}

.user-info p {
  margin: 8px 0; /* Espacement vertical entre les lignes */
  font-size: 14px; /* Taille de police */
}

/* Conteneur principal */
.card-details {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.subscription-summary {
  background-color: #fdf8ff; /* Fond gris clair */
  border: 1px solid #e0e0e0; /* Bordure légère pour délimiter */
  border-radius: 10px; /* Coins arrondis pour un aspect moderne */
  padding: 20px; /* Espacement interne généreux */
  margin-top: 20px; /* Espacement au-dessus */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre subtile pour relief */
  color: #99a737; /* Couleur de texte standard */
}

.subscription-summary p {
  margin: 10px 0; /* Espacement vertical entre les paragraphes */
  font-size: 1.2rem;
  line-height: 1.5; /* Espacement entre les lignes */
}

/* Conteneur de CardElement */
.input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 12px; /* Ajuste les padding */
  width: 100%;
  min-height: 35px; /* Assure une hauteur minimum pour le texte */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

/* Conteneur de CardElement pour occuper tout l’espace */
.card-element-container {
  flex: 1;
}

.title {
  font-weight: bold;
}

.subtitle {
  font-size: 1.2rem;
  color: #4a42bd;
  margin-top: -13px;
}

.form-checkout a {
  color:rgb(103, 68, 179);
}

.styled-link, .styled-link2 {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: transform 0.2s ease, color 0.2s ease;
}

.styled-link i {
  margin-right: 8px; 
  color: #f1c40f; 
  transition: color 0.2s ease;
}

.styled-link:hover, .styled-link:hover i {
  transform: scale(1.05);
  color: #f1c40f;
}

.styled-link2 {
  transition: color 0.2s ease;
}

.styled-link2:hover {
  color: #f1c40f;
}

/* selection */
.radio-option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 12px 24px;
  border: 2px solid #dcdcdc;
  border-radius: 16px;
  font-size: 16px;
  font-weight: bold;
  color: #555;
  cursor: pointer;
  background-color: #fefefe;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(255, 255, 255, 0.8);
  transition: all 0.2s ease-in-out;
}

.radio-option input {
  display: none;
}

.radio-option.selected {
  background-color: #fff8e0;
  color: #b58900;
  border-color: #f9c74f;
  box-shadow: inset 3px 3px 6px rgba(0, 0, 0, 0.15), inset -3px -3px 6px rgba(255, 255, 255, 0.8);
}

.radio-option:hover {
  background-color: #fdfdfd;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1), -4px -4px 8px rgba(255, 255, 255, 0.8);
}

.radio-option:active {
  transform: scale(0.98);
  background-color: #fff3d1;
  box-shadow: inset 5px 5px 10px rgba(0, 0, 0, 0.2), inset -5px -5px 10px rgba(255, 255, 255, 0.9);
}

/* checkbox */
.terms-section {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  color: #333; /* Couleur neutre pour contraste */
}

.terms-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
}

.terms-link {
  color: rgb(0, 110, 255); /* Assorti à votre thème */
  text-decoration: underline;
}

.terms-link:hover {
  text-decoration: none;
}

.terms-section input[type='checkbox'] {
  display: inline-block;
}

/* boutons */
.payment-submit:focus { 
  border-color: #8000ff; /* Bordure violette */
  box-shadow: 0 0 5px rgba(128, 0, 255, 0.5); /* Glow léger */
  outline: none; /* Supprime le contour par défaut */
}

.payment-submit:hover {
  background-color: #9a5def; /* Couleur de fond au survol */
  border-color: gold; /* Bordure dorée */
  transform: translateY(-2px); /* Légère remontée */
}

.payment-submit:active {
  transform: translateY(0); /* Remet le bouton à sa place */
}

.spinner {
  display: inline-block;
  width: 20px; /* Taille du spinner */
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.1); /* Bordure légère pour le contour */
  border-top: 3px solid #6A5ACD; /* Couleur principale, ajuste selon ton design */
  border-radius: 50%; /* Forme circulaire */
  animation: spin 1s linear infinite; /* Animation de rotation */
  vertical-align: middle; /* Alignement vertical avec le texte */
  margin-right: 8px; /* Espace entre le spinner et le texte (si utilisé) */
}

/* Animation de rotation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* reduction */

.discount-section {
  margin: 15px 0;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 8px;
}

.discount-input-group {
  display: flex;
  gap: 8px;
}

.discount-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.discount-button {
  background: #f0f0f0;
  border: 1px solid #ddd;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.discount-button:hover {
  background: #e0e0e0;
}

.discount-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* =============================
   FOOTER
============================= */

.footer {
  background-color: #774dc0;
  z-index: 10;
  color: white;
  min-height: 100px;
  width: 100%;
  text-align: center;
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
}

.footer p {
  font-size: 1.2rem;
  text-align: center;
}

.footer ul {
  list-style: none;
  padding: 0; 
  margin: 20px 0; 
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap; 
}

.footer ul li {
  margin: 0 15px; /* Marge horizontale conservée */
  border: none; /* Reset de la bordure */
}

.footer a {
  color: #ecf0f1; 
  text-decoration: none; 
  font-weight: bold; 
}

.footer a:hover {
  color: #f0d22c; 
}

@media (max-width: 768px) { /* Exemple pour petits écrans */
  .footer ul {
    flex-direction: column; /* Passe en vertical */
    align-items: center; /* Centre les éléments */
  }

  .footer ul li {
    margin: 10px 0; /* Marge verticale au lieu d’horizontale */
  }
}

/* =============================
   Verification.js
============================= */

.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 90%;
  background-color: #f5f5f5;
  padding: 20px;
}

.verification-container h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
}

.verification-container p {
  color: #666;
  font-size: 18px;
  text-align: center;
  max-width: 600px;
  margin-bottom: 10px;
}

.verification-container p.error {
  color: #e45a55;
  font-weight: bold;
}

/* =============================
   MODULES LUDIQUES
============================= */

/* Conteneur principal centré pour le module */
.bordure-module {
  display: inline-flex; /* S'adapte à la taille de l'élément enfant */
  border: 2px solid white;
  border-radius: 15px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  /* Optionnel: limite la largeur maximale si nécessaire */
  max-width: 100vw; 
  max-height: 100vh;
}

.module-container-wborder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  overflow: hidden;
}

.module-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/* Style de l'iframe */
.responsive-iframe {
  border: none;
  overflow: hidden;
  width: 100%;           /* Pleine largeur par défaut */
  height: 100%;          /* Pleine hauteur par défaut */
  min-width: 600px;      /* Largeur minimale pour les grands écrans */
  min-height: 640px;     /* Hauteur minimale */
}

/* Pour écrans de taille moyenne */
@media (max-width: 768px) {
  .responsive-iframe {
    width: 95vw;          /* Réduction à 95% pour limiter les débordements */
    height: 95vh;         /* Hauteur ajustée pour les écrans moyens */
    max-width: 600px;     /* Largeur maximale stricte pour petits écrans */
    max-height: 600px;    /* Hauteur maximale pour éviter tout rognage */
  }
}

/* Pour très petits écrans (moins de 560px) */
@media (max-width: 560px) {
  .responsive-iframe {
    width: 90vw;          /* Largeur réduite à 90% pour assurer la visibilité */
    height: 90vh;         /* Hauteur réduite également */
    max-width: 500px;     /* Largeur maximale stricte pour éviter le rognage */
    max-height: 500px;    /* Hauteur maximale pour correspondre à l’écran */
    min-width: 300px;     /* Largeur minimale pour maintenir la lisibilité */
    min-height: 400px;    /* Hauteur minimale similaire */
  }
}

/* =============================
   ACCESSIBILITE
============================= */

.accessibilite {
  position: fixed;
  left: 1%; /* Ajuste selon l'espace souhaité */
  top: 65px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 8px;
  gap: 3px;
  z-index: 3;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none;
}

.accessibility-trigger {
  position: fixed;
  left: 1%;
  top: 65px;
  width: 10%;
  height: 30%;
  background: transparent; 
  cursor: pointer;
  z-index: 3;
}

.fadeIn {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}

.fadeOut {
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none; 
}

.accessibility-button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  width: 48px; 
  height: 48px; 
  background-repeat: no-repeat;
  background-position: center;
  margin: 5px;
}

.accessibility-button-container {
  position: relative;
}

.color-options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  position: absolute;
  right: -40px;
  top: 0;
  background: #ffffff4f;
  border: 1px solid #ccccccc4;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.color-options .color-square {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: transform 0.2s;
}

.color-options .color-square:hover {
  transform: scale(1.1);
}

.accessibility-button-container:hover .color-options {
  opacity: 1;
  visibility: visible;
}

.text-size-buttons {
  display: flex;
  flex-direction: column;
}

.dark-mode-button {
  background-image: url('./pictures/dark-mode.png');
}

.light-mode-button {
  background-image: url('./pictures/light-mode.png');
}

.text-larger-button {
  background-image: url('./pictures/text-plus.png');
}

.text-smaller-button {
  background-image: url('./pictures/text-minus.png');
}

/* CookieBanner */
.cookie-banner {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 320px; /* Taille compacte */
  background: #4a4617c7; /* Fond noir profond pour contraste */
  padding: 12px 16px;
  border-radius: 20px;
  border: 1px solid #d4af37; /* Bordure dorée subtile */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.cookie-text {
  margin: 0 0 8px 0;
  font-size: 12px;
  line-height: 1.4;
  color: hsl(50, 46%, 82%); /* Texte doré */
  font-family: 'Arial', sans-serif;
}

.cookie-buttons {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}

.cookie-button {
  width: 48%;
  padding: 6px 0;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: background-color 0.2s ease, transform 0.1s ease;
  font-family: 'Arial', sans-serif;
}

.cookie-button:active {
  transform: scale(0.95); /* Effet de clic subtil */
}

.cookie-button-accept {
  background: linear-gradient(135deg, #d4af37, #b5932b); /* Dégradé doré */
  color: #1a1a1a;
}

.cookie-button-accept:hover {
  background: linear-gradient(135deg, #e6c34b, #c7a539);
}

.cookie-button-refuse {
  background: #333333; /* Gris foncé pour contraste */
  color: #d4af37;
  border: 1px solid #d4af37;
}

.cookie-button-refuse:hover {
  background: #444444;
}

.cookie-button-customize,
.cookie-button-save {
  background: transparent;
  color: #d4af37;
  border: 1px solid #d4af37;
}

.cookie-button-customize:hover,
.cookie-button-save:hover {
  background: rgba(212, 175, 55, 0.1); /* Fond doré léger au survol */
}

/* =============================
   MODE SOMBRE
============================= */

body[data-theme="dark"] {
  background-color: #6b44aa; /* Fond plus sombre */
  color: #f8f8f8; /* Texte plus clair */
}

body[data-theme="dark"] .home {
  background-color: #6b44aa;
}

body[data-theme="dark"] .programme-item {
  background-color: rgba(112, 154, 233, 0.281);
}

body[data-theme="dark"] .textAdapte a {
  color: #dfcee7;
}
body[data-theme="dark"] .textAdapte a:hover {
  color: #c754cf;
}

body[data-theme="dark"] .textAdapte p { 
  color: #e2cbdd;
}

body[data-theme="dark"] .headerColor {
  background-color: rgba(32, 233, 99, 0.418);
}

body[data-theme="dark"] .nav-link {
  color: aliceblue;
}

body[data-theme="dark"] .gold {
  color: rgb(204, 180, 41);
}

body[data-theme="dark"] .gold:hover {
  color: rgb(219, 194, 52);
}

body[data-theme="dark"] p.title {
  color: #4a42bd;
}

body[data-theme="dark"] p.subtitle {
  color: #aca92a;
}

body[data-theme="dark"] .styled-link {
  color: #6b61ee;
}

body[data-theme="dark"] .styled-link:hover {
  color: #a560cc;
}

body[data-theme="dark"] .subscription-summary p {
  color: #a445b2;
}

/* Fond blanc */
body.fond-blanc,
body.fond-blanc p,
body.fond-blanc h1,
body.fond-blanc h2,
body.fond-blanc h3,
body.fond-blanc li {
  color: #000000; /* Texte noir sur fond blanc */
}
body.fond-blanc li:hover {
  color: #cfbc0e;
}

body.fond-cyan .name{
  color:rgb(0, 110, 255);
}

body.fond-cyan a{
  color:rgb(236, 201, 77);
  transition: color 0.2s ease;
}
body.fond-cyan a:hover{
  color:rgb(192, 196, 154);
}
body.fond-cyan .listeCours_p p {
  color:rgb(241, 241, 241) !important;
}