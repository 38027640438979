.questionnaire, .resultats {
  background: rgba(255, 255, 255, 0.523);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  text-align: center;
  margin-bottom: 4%;
}

.questionnaire h2 {
  color: rgba(233, 217, 232, 0.799);
}

h1 {
  color: rgba(255, 255, 255, 0.658)
}

.description {
  font-size: 1.2rem;
  font-style: italic;
  color: #e2e2e2;
  padding-top: 10px;
  border-radius: 5px;
  margin-bottom: 15px;
  text-align: left;
}

.result-page button, .questionnaire button {
  user-select: text;
  display: block;
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn-blue {
  background-color: #007bffc5;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-green {
  background-color: #1f9236;
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-blue:hover {
  background-color: #346becea;
}

.btn-green:hover {
  background-color: #218838ea;
}

/* Barre de progression */
.progress-bar-container {
  width: 100%;
  height: 10px;
  background-color: #ddd;
  border-radius: 5px;
  margin-bottom: 15px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-color: #007BFF;
  transition: width 0.3s ease;
}

.text-input {
  width: 100%;
  min-height: 120px;  /* Hauteur de base plus grande */
  max-height: 300px;  /* Hauteur max pour éviter qu'il devienne trop grand */
  font-size: 1rem;
  padding: 10px;
  border: 2px solid #007BFF;
  border-radius: 5px;
  resize: vertical;  /* Permet à l'utilisateur d'agrandir si besoin */
  box-sizing: border-box;
}

/* Adaptation responsive */
@media (max-width: 600px) {
  .text-input {
    min-height: 100px;
    font-size: 0.9rem;
  }
}

/* 📜 Bloc Résumé */
.result-page {
  background: rgba(255, 255, 255, 0.461);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  max-width: 650px;
  margin-bottom: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}

.result-text {
  font-size: 1.2rem;
  line-height: 1.4;
  text-align: left;
  color: #333;
  background: rgba(255, 255, 255, 0.778);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  min-height: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-text hr {
  border: none;    
  border-top: 2px solid #3333337e; 
  width: 100%;       
  margin: 20px 0;         
}

/* Mise en valeur des parties clés */
.result-text strong {
  color: #9d4edd;
  font-weight: bold;
}

/* Mise en avant de certaines parties */
.result-text em {
  color: #007BFF;
  font-style: italic;
}

/* Ajout de couleurs pour les blocs de résumé */
.result-text p:nth-child(odd) {
  background: rgba(157, 78, 221, 0.1);
  padding: 10px;
  border-radius: 8px;
}

.result-text p:nth-child(even) {
  background: rgba(0, 123, 255, 0.1);
  padding: 10px;
  border-radius: 8px;
}